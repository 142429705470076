import React from "react";
import styled from "styled-components";
import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image"
import Image from "./image";
import { Dialog } from '@reach/dialog';
import '@reach/dialog/styles.css';
import { styles } from "./styles";
import parse from 'html-react-parser';

const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if(result){
      var r= parseInt(result[1], 16);
      var g= parseInt(result[2], 16);
      var b= parseInt(result[3], 16);
      return r+","+g+","+b;//return 23,14,45 -> reformat if needed 
  }
  return null; 
}

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    
    .galleryGrid__overflow {
      height: 100%;
      width: 100%;
      overflow: hidden;

      // .gatsby-image-wrapper {
      //   &::before {
      //     content: '';
      //     display: block;
      //     width: 100%;
      //     padding-bottom: 151.80995475113122%;
      //   }
      // }
    }

    .galleryGrid__sizer,
    .galleryGrid__item {
      width: 100%;

      @media(min-width: 576px) {
        width: 33.33%;
      }
    }

    .galleryGrid__item {
      display: block;
      overflow: hidden;
      cursor: pointer;

      &.active {
        display: block;
      }

      &.wide {
        @media(min-width: 576px) {
          width: 66.66%;
        }
      }

      .gatsby-image-wrapper {

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 2;
          border: 5px solid #fff;
          transition: transform .3s ease;
        }

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
        }
      }

      &:hover {
        .gatsby-image-wrapper {
          //transform: scale(1.05);

          &::before {
            transform: scale(.85);
          }

          &::after {
            background-color: rgba(${hexToRgb('#ababab')}, 0.45);
          }
        }
      }
    }
`;

const ModalContent = styled.div`
    display: block;
    position: relative;

    .gatsby-image-wrapper {
      width: 100%;

      picture img {
        position: static !important;
      }
    }
`;

const Close = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  background-color: transparent;
  border: 0;
  padding: 10px;
  position: absolute;
  top: 1rem;
  right: 1rem;
  transition: background-color .3s ease;
  cursor: pointer;
  z-index: 1;

    &::before, &::after {
      content: '';
      height: 5px;
      width: 30px;
      background-color: #fff;
      position: absolute;
      top: 50%;
      transform-origin: center;
      border-radius: 25px;
      transition: background-color .3s ease;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }

    &:hover {
      background-color: #fff;

      &::before, &::after {
        background-color: ${styles.colorPrimary};
      }
    }

    &:focus {
      outline: 0;
    }

    @media (min-width 576px) {
      top: 2rem;
      right: 2rem;
    }
`;

const ModalBtn = styled.button`
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  text-transform: uppercase;
  color: ${styles.colorPrimary};
  background-color: #fff;
  border: 1px solid #fff;
  padding: 7px 26px;
  position: absolute;
  left: 2rem;
  bottom: 2rem;
  cursor: pointer;
  transition: color .2s ease, background-color .3s ease;
  z-index: 2;

  &:hover {
    color: #fff;
    background-color: ${styles.colorPrimary};
  }
`;

const Discription = styled.div`
  display: none;
  font-size: 12px;
  line-height: 27px;
  background-color: #fff;
  padding: 3rem 2rem 1rem 2rem;
  margin: 0;
  //transform: translateY(100%);
  position: relative;
  left: 0;
  right: 0;
  bottom: 5rem;
  z-index: 1;

  &.active {
    display: block;
  }
`;

class Gallery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showLightbox: false,
      selectedImage: null,
      isDescription: false,
      showDiscription: false,
      imageDiscription: null,
    };
  }

  categoriesFiller = (categories) => {
    const arr = [];
    let obj = {};
    console.log(categories)
    categories.map(el => {
      const altArr = el.portfolioGaleriaNazwaKategorii.split(',')
      altArr.forEach((cat) => {
        cat = cat.replace(' ', '');
        if (!arr.includes(cat)) {
          arr.push(cat);
          const nnn = {[cat] : 0}
          obj = {...obj, ...nnn}
        }
      })
    });

    return obj
  }

  addCategoryClass = (obj, categories) => {
    const classesArr = categories.split(',')
    classesArr.forEach((item) => {
      item = item.replace(' ', '');
      obj[item] = obj[item]+1;
      if (obj[item]%9 === 0 || obj[item] === 3) {
        classesArr.push('wide');
      }
    });
    
    const classes = classesArr.join(' ');
    return classes
  }

  clickHandler = (e, src, caption) => {
    e.preventDefault();
    console.log(caption);
    this.setState({ showLightbox: true, selectedImage: src, imageDiscription: caption });
    if (caption !== null) {
      this.setState({ isDescription: true });
    }
  }

  render() {
    const gallery = this.props.data.portfolioGaleria;
    console.log(gallery)
    const obj = this.categoriesFiller(gallery);
    const { showLightbox, selectedImage, isDescription, showDiscription, imageDiscription } = this.state;
    console.log(imageDiscription);

    return (
      <>
        <Wrapper className="galleryGrid">
          <div class="grid-sizer"></div>
          {gallery.map(category => (
            <>
              {category.portfolioGaleriaKategoria !== null ?
              category.portfolioGaleriaKategoria.map(image => (
                <a className={`galleryGrid__item ${this.addCategoryClass(obj, category.portfolioGaleriaNazwaKategorii)}`} onClick={(e) => this.clickHandler(e, image.portfolioGaleriaKategoriaObraz.localFile.childImageSharp.fluid, image.portfolioGaleriaKategoriaOpis)} data-name={category.portfolioGaleriaNazwaKategorii} href={image.portfolioGaleriaKategoriaObraz.localFile.publicURL} target="_blank">
                  <div className="galleryGrid__overflow">
                    <Img fluid={image.portfolioGaleriaKategoriaObraz.localFile.childImageSharp.fluid} />
                  </div>  
                </a>
              )) : null}
            </>
          ))}
        </Wrapper>
        {showLightbox && (
        <Dialog onDismiss={() => this.setState({ showLightbox: false, selectedImage: null, isDescription: false, showDiscription: false, imageDiscription: null })}>
          <ModalContent>
            <Close onClick={() => this.setState({ showLightbox: false, selectedImage: null, isDescription: false, showDiscription: false, imageDiscription: null })} />
            <Img fixed={selectedImage}/>
            {/* <img src={selectedImage}/> */}
              {isDescription && (
                <ModalBtn onClick={() => this.setState(prevState => ({ showDiscription: !prevState.showDiscription }))}>opis</ModalBtn> 
              )} 
          </ModalContent>
          <Discription className={showDiscription ? 'active' : '' }>{imageDiscription !== null ? parse(imageDiscription) : ''}</Discription>
        </Dialog>
        )}
      </>
    )
  }
}

export default Gallery