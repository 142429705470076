import React from "react";
import styled from "styled-components";
import { intro } from "./keyframes/intro"
import { finger } from "./keyframes/finger"

const Mouse = styled.div`
    position absolute;
    width 22px;
    height 42px;
    bottom 90px;
    left 50%;
    margin-left -12px;
    border-radius 15px;
    border 2px solid #FFF;
    animation ${intro} 1s;
    z-index: 2;
`;


const Scroll = styled.div`
  display block;
  width 3px;
  height 3px;
  margin 6px auto;
  border-radius 4px;
  background #FFF;
  animation ${finger} 1s infinite;
`; 

const MouseIcon = () => {

    return (
        <Mouse>
            <Scroll />
        </Mouse>
    )
}

export default MouseIcon