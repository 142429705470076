import React from "react"
import styled from "styled-components"
import { FlexBox, Text, styles } from "./styles"
import FormAcceptance from "./form-acceptance"
import SubmitIcon from "../images/submit.inline.svg"
import Fade from 'react-reveal/Fade';

const Wrapper = styled.div`
  padding: 16px;

  label {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;

      @media (min-width: 992px) {
          flex-direction: row;
      }
  }

  @media (min-width: 576px) {
    padding: 0;
  }
`;

const FormInput = styled.input`
    height: 35px;
    width: 100%;
    border: 0;
    border-bottom: 1px solid ${styles.colorPrimary};
    font-size: 16px;
    line-height: 35px;
    margin-bottom: 30px;

    @media (min-width: 992px) {
        height: 20px;
        max-width: 228px;
        font-size: 12px;
        line-height: 20px;
        margin-left: 30px;
        margin-bottom: 0;
    }
`;

const FormTextarea = styled.textarea`
    height: 160px;
    width: 100%;
    max-width: 445px;
    border: 1px solid ${styles.colorPrimary};
    font-size: 12px;
    line-height: 20px;
    padding-right: 50px;
    resize: none;

    @media (min-width: 576px) {
      height: 94px;
    }
`;

const FormButton = styled.button`
    display: flex;    
    height: 19px;
    width: 24px;
    background-color: transparent;
    margin: 0;
    padding: 0;
    border: 0;
    position: absolute;
    right: 10px;
    bottom: 10px;

    svg {
        path {
            transition: fill .3s ease;
        }
    }

    &:hover {
        cursor: pointer;

        svg {
            path {
                fill: #000;
            }
        }
    }
`;

const Form = () => {

    return (
        <Wrapper>
            <form name="Contact" method="post" netlify-honeypot="bot-field" data-netlify="true">
                <input type="hidden" name="bot-field" />
                <FlexBox direction={"column"}>
                    <Fade bottom>
                        <label for="email">
                            <Text color={styles.colorPrimary} mb={"12px"}>Twój adres e-mail:</Text>
                            <FormInput type="email" name="email" required/>
                        </label>
                    </Fade>
                    <Fade bottom>
                        <label for="topic">
                            <Text color={styles.colorPrimary} mb={"12px"}>Temat wiadomości:</Text>
                            <FormInput type="text" name="topic" required/>
                        </label>
                    </Fade>
                </FlexBox>
                <Fade bottom>
                <label for="message" style={{position: "relative"}}>
                    <FormTextarea type="text" name="message"/>
                    <FormButton type="submit">
                        <SubmitIcon />
                    </FormButton>
                </label>
                </Fade>
                <FormAcceptance/>
            </form>
        </Wrapper>
    )
}

export default Form