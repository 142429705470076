import React from "react";
import styled from "styled-components";
import { styles } from "./styles"
import { Link } from "gatsby";
import Fade from 'react-reveal/Fade';

const Wrapper = styled.div`
  width: 100%;
  max-width: 445px;
  padding: 15px 0;
`;

const Checkmark = styled.span`
  display: block;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid ${styles.colorPrimary};
  z-index: 0;
  transform: scale(1);
  vertical-align: middle;
  background-color: #fff;
  transition: all .2s ease;
  margin-right: 15px;
  cursor: pointer;

  svg {
    height: 16px;
    width: 16px;
    position: absolute;
    top: 1px;
    left: 1px;
    fill: none;
    stroke: ${styles.colorPrimary};
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 16;
    stroke-dashoffset: 16;
    transition: all 0.3s ease;
    transition-delay: 0.1s;
    transform: translate3d(0, 0, 0);
    z-index: 99;
  }

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    transform: scale(0);
    opacity: 1;
    border-radius: 50%;
  }

  &::after {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
  }

  .active {
    background: #fff;
    border-color: transparent;
    animation: wave .4s ease;

    &::before {
      transform: scale(3.5);
      opacity: 0;
      transition: all .6s ease;
    }
  }

  @media (min-width: 992px) {
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    max-width: 16px;
    max-height: 16px;

    svg {
      height: 14px;
      width: 14px;
    }
  }
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  position: relative;

  .checkbox:checked ~ .checkmark {
    background: #fff;
    border-color: transparent;
    animation: wave .4s ease;

    svg {
      stroke-dashoffset: 0;
    }

    &::before {
      transform: scale(3.5);
      opacity: 0;
      transition: all .6s ease;
    }
  }
`;

const Checkbox = styled.input`
  width: 29px;
  height: 20px;
  position: absolute;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
`;

const Text = styled.p`
  display: block;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  color: ${styles.colorGray};
  letter-spacing: 0.1em;

  @media (min-width: 992px) {
    font-size: 8px;
    line-height: 14px;
  }
`;

const AcceptanceText = styled.p`
  display: block;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  color: ${styles.colorGray};
  padding-left: 30px;
  margin: 0;
  letter-spacing: 0.1em;

  @media (min-width: 992px) {
    font-size: 8px;
    line-height: 14px;
  }
`;

const RodoLink = styled(Link)`
  color: ${styles.colorPrimary};
  text-decoration: none;
`;

class FormAcceptance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      details: [
        { id: 0, name: "checkbox", active: false },
      ]
    };
  }

  handleClick = (e) => {
    e.target.closest('input').classList.toggle('active');
  };

  render() {
    return (
      <>
        <Wrapper>
          <Fade bottom>
            <Text>
              Państwa dane przetwarzane będą wyłącznie w celu udzielenia odpowiedzi na zapytanie zgodnie z zasadą, która głosi, że przetwarzanie danych jest zgodne z prawem jeżeli jest niezbędne w celu realizacji umowy lub przed jej zawarciem. 
            </Text>
          </Fade>
          <Fade bottom>
            <Label>
              <Checkbox className={"checkbox"} type={"checkbox"}/>
              <Checkmark className={"checkmark"}>
                <svg width="8px" height="8px" viewBox="-1 0 16 12">
                  <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                </svg>
              </Checkmark>
              <AcceptanceText>
                Zgadzam się na kontakt w celu uzyskania odpowiedzi na wiadomość zawartą w formularzu kontaktowym. 
              </AcceptanceText>
            </Label>
          </Fade>
        </Wrapper>
      </>
    )
  }
}

export default FormAcceptance;
