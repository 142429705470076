import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import styled from "styled-components";
import Img from "gatsby-image"
import { styles } from "./styles";
// import Slider from "./slider";
import Mouse from "./mouse-icon"

const Slider = React.lazy(() => import('../components/slider'));

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: ${styles.colorPrimary};

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;

    @media (min-width: 992px) {
      display: none;
    }
  }

  .awssld {
    display: none;

    @media (min-width: 992px) {
      display: block;
    }
  }

  figure {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
  }
`;

const Hero = ({ data }) => {
  const isSSR = typeof window === "undefined"
  return (
    <Wrapper className={"hero"}>
      <Img fixed={data.sliderMobile.localFile.childImageSharp.fluid} />
      {!isSSR && (
      <React.Suspense fallback={<div />}>
        <Slider data={data.sliderDesktop}/>
      </React.Suspense>
      )}
      <Mouse /> 
    </Wrapper>  
  )
}

export default Hero
