import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components";
import Img from "gatsby-image"
import Image from "../components/image";
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Container from "../components/container"
import { SectionA, SectionB, FlexBox, Title2, Title3, Text, Link, styles } from "../components/styles"
import Fade from 'react-reveal/Fade';
import SocialIcon from "../components/social-icon"
import ParallaxPhoto from "../components/parllax-photo"
import Gallery from "../components/gallery"
// import CategoriesList from "../components/categories-list"
import Carousel from "../components/carousel"
import Form from "../components/form"

const BeforeAfter = React.lazy(() => import('../components/beforeafter'));
const CategoriesList = React.lazy(() => import('../components/categories-list'));

// About

const AboutTextbox = styled(FlexBox)`
  width: 100%;
  padding-bottom: 26px;

  @media(min-width: 992px) {
    width: 40%;
    padding: 0 58px;
  }
`

const AboutImageBox = styled(FlexBox)`
  width: 100%;

  @media(min-width: 992px) {
    width: 60%;
    border-left: 1px solid ${styles.colorPrimary};
    padding: 0 58px;
  }

  .gatsby-image-wrapper {
    &::before {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 151.80995475113122%;
    }
  }
`

const AboutSocialBox = styled(FlexBox)`
  margin-bottom: 18px;

  .instagram {
    margin-left: 20px;
  }
`;

const AboutImageWrapper = styled.div`
  width: 100%;
  padding-bottom: 26px;

  @media (min-width: 992px) {
    padding-bottom: 80px;
    max-width: 440px;
  }
`;

// Portfolio

const PortfolioCategoriesBox = styled(FlexBox)`
  display: none;
  width: 100%;

  @media(min-width: 576px) {
    display: flex;
    order: 2;
  }

  @media(min-width: 992px) { 
    width: 60%;
    border-right: 1px solid ${styles.colorPrimary};
    padding: 0 58px;
    order: 1;
  }
`

const PortfolioTextBox = styled(FlexBox)`
  width: 100%;
  order: 1;

  @media(min-width: 992px) {
    width: 40%;
    padding: 0 58px;
    order: 2;
  }
`

// Retouch

const RetouchTextBox = styled(FlexBox)`
  width: 100%;

  @media(min-width: 992px) {
    width: 40%;
    padding-right: 58px;
  }

  .react-reveal {
    margin-bottom: auto;
  }
`

const RetouchImageBox = styled(FlexBox)`
  width: 100%;

  @media(min-width: 992px) {
    width: 60%;
    border-left: 1px solid ${styles.colorPrimary};
    padding-left: 58px;
  }
`

const RetouchImageWrapper = styled.div`
  width: 100%; 
  padding-bottom: 26px;
  overflow: hidden;
  display: flex;
  justify-content: center;

  @media (min-width: 992px) {
    padding-bottom: 80px;
    max-width: 560px;
  }
`;

// Contact

const ContactAddressBox = styled(FlexBox)`
  width: 100%;

  a {
    font-size: 12px;
    line-height: 27px;
    color: ${styles.colorPrimary};
    text-decoration: none;
    transition: color .3s ease;

    &:hover {
      color: #000;
    }
  }

  @media(min-width: 992px) {
    width: 50%;
  }
`;

const ContactFormBox = styled(FlexBox)`
  width: 100%;
  padding-top: 50px;

  @media(min-width: 992px) {
    width: 50%;
    padding-top: 0;
  }
`;

const ContactSocialBox = styled(FlexBox)`
  a {
    margin-left: 20px;

    &:nth-of-type(1) {
      margin-left: 14px;
    }
  }

  @media(min-width: 992px) {
    padding-left: 10px;
  }
`;

const IndexPage = ({ data }) => {

  const isSSR = typeof window === "undefined";

  return(

  <Layout wpData={data.wpPage.ustawienia}>
    
    <SEO title="Index" data={data.wpPage.ustawienia} />
    <Hero data={data.wpPage.slider} />
    <SectionA id="o-nas">
      <Container>
        <FlexBox align={"stretch"} direction={"column"} directionLG={"row"}>
          <AboutTextbox direction={"column"} justify="space-between" alignLG={"flex-end"}>
            <Fade bottom>
                <Title2 mb={"25px"}>{data.wpPage.oNas.oNasTytul}</Title2>
            </Fade>
            <Fade bottom>
              <Text alignLG={"right"} maxWidthLG={"365px"} mb={"25px"}>{data.wpPage.oNas.oNasTresc}</Text>
            </Fade>
            <FlexBox direction={"column"} alignLG={"flex-end"}>
              <AboutSocialBox justify={"flex-end"}>
                <Fade bottom>
                  <SocialIcon className={"facebook"} media={"facebook"} to={data.wpPage.ustawienia.oNasAdresFacebooka} color={styles.colorPrimary} />
                  <SocialIcon className={"instagram"} media={"instagram"} to={data.wpPage.ustawienia.oNasAdresInstagrama} color={styles.colorPrimary} />
                </Fade>
              </AboutSocialBox>
              <Fade bottom>
                <Link to={"#kontakt"} mb={"18px"}>Kontakt</Link>
              </Fade>
              <Fade bottom>
                <Link to={"#retusz-zdjec"}>Retusz zdjęć</Link>
              </Fade>
            </FlexBox>
          </AboutTextbox>
          <AboutImageBox direction={"column"}>
            <AboutImageWrapper>
              <Fade bottom>
                <Img fixed={data.wpPage.oNas.oNasObrazek.localFile.childImageSharp.fluid} />
              </Fade>
            </AboutImageWrapper>
            <Fade bottom>
              <Text>{data.wpPage.oNas.oNasOpisPodObrazkiem}</Text>
            </Fade>
          </AboutImageBox>
        </FlexBox>
      </Container>
    </SectionA>
    <SectionB>
      <ParallaxPhoto />
    </SectionB>
    <SectionA id="portfolio" display={"block"} displayLG={"block"}>
      <Container>
        <FlexBox align={"stretch"} direction={"column"} directionLG={"row"} p={"0 0 25px 0"}>
          <PortfolioCategoriesBox align={"center"}>
            {!isSSR && (
            <React.Suspense fallback={<div />}>
              <Fade bottom>
                <CategoriesList data={data.wpPage.portfolio} />
              </Fade>
            </React.Suspense>
            )}
          </PortfolioCategoriesBox>
          <PortfolioTextBox direction={"column"} justify="space-between">
            <Fade bottom>
              <Title2 mb={"25px"}>{data.wpPage.portfolio.portfolioTytul}</Title2>
            </Fade>
            <Fade bottom>
              <Text maxWidthLG={"365px"} mb={"25px"}>{data.wpPage.portfolio.portfolioTresc}</Text>
            </Fade>
          </PortfolioTextBox>
        </FlexBox>
        <Gallery data={data.wpPage.portfolio} />
      </Container>
    </SectionA>
    <SectionA id="retusz-zdjec">
      <Container>
        <FlexBox align={"stretch"} direction={"column"} directionLG={"row"}>
          <RetouchTextBox direction={"column"} justify="center" alignLG={"flex-end"}>
            <Fade bottom>
              <Title2 mb={"auto"}>{data.wpPage.retuszZdjec.retuszZdjecTytul}</Title2>
            </Fade>
            <Fade bottom>
              <Text alignLG={"right"} maxWidthLG={"345px"} mb={"45px"}>{data.wpPage.retuszZdjec.retuszZdjecTresc}</Text>
            </Fade>
          </RetouchTextBox>
          <RetouchImageBox direction={"column"} justify="space-between" align={"flex-start"}>
            <RetouchImageWrapper>
              {!isSSR && (
              <React.Suspense fallback={<div />}>
                <Fade bottom>
                  <BeforeAfter before={data.wpPage.retuszZdjec.retuszZdjecObrazekPrzed.localFile.publicURL} after={data.wpPage.retuszZdjec.retuszZdjecObrazekPo.localFile.publicURL}/>
                </Fade>
              </React.Suspense>
              )}
            </RetouchImageWrapper>
            <Fade bottom>
              <Text maxWidthLG={"560px"}>{data.wpPage.retuszZdjec.retuszZdjecOpisPodObrazkiem}</Text>
            </Fade>
          </RetouchImageBox>
        </FlexBox>
      </Container>
    </SectionA>
    <SectionA>
      <Container>
        <Fade bottom>
          <Title3 alignSM={"center"} mb={"60px"}>partnerzy:</Title3>
        </Fade>
        <Fade bottom>
          <Carousel data={data.wpPage.partnerzy} />
        </Fade>
      </Container>
    </SectionA>
    <SectionA id="kontakt">
      <Container>
        <FlexBox direction={"column"} directionLG={"row"}>
          <ContactAddressBox direction={"column"} align="center" alignLG="flex-start">
            <div style={{ width: `100%`, maxWidth: `330px`, marginBottom: `46px` }}>
              <Fade bottom>
                {/* <StaticImage 
                  src="../images/partner2.jpg"
                  width={312}
                  quality={85}
                  formats={["auto", "webp", "avif"]}
                  alt="Logo Piękne Zdjęcie"
                /> */}
                <Image filename={"logo-color.png"} /> 
              </Fade>
            </div>
            <Fade bottom>
              <Text p={"0"} pLG={"0 0 0 10px"}>email: <a href="mailto:kontakt@pieknezdjecie.pl">kontakt@pieknezdjecie.pl</a></Text>
            </Fade>
            <Fade bottom>
              <Text p={"0"} pLG={"0 0 0 10px"} mb={"22px"}>tel.: <a href="tel:48792292140">792 292 140</a></Text>
            </Fade>
            <ContactSocialBox align="center">
              <Fade bottom>
                <Text>Śledź nas w social mediach!</Text>
              </Fade>
              <Fade bottom>
                <SocialIcon media={"facebook"} to={data.wpPage.ustawienia.oNasAdresFacebooka} color={styles.colorPrimary}/>
              </Fade>
              <Fade bottom>
                <SocialIcon media={"instagram"} to={data.wpPage.ustawienia.oNasAdresInstagrama} color={styles.colorPrimary}/>
              </Fade>
            </ContactSocialBox>
          </ContactAddressBox>
          <ContactFormBox justify="center" justifyLG="flex-end">
            <Form />
          </ContactFormBox>
        </FlexBox>
      </Container>
    </SectionA>
  </Layout>
  )
}
export default IndexPage

export const pageQuery = graphql`
  query WordpressPage {
    wpPage(id: { eq: "cG9zdDoy" }) {
      title
      ustawienia {
        oNasAdresInstagrama
        oNasAdresFacebooka
        ustawieniaKolorPrzewodni
        ustawieniaOpisStrony
        ustawieniaTytulStrony
      }
      slider {
        sliderDesktop {
          sliderDesktopObrazek {
            sourceUrl
            localFile {
              publicURL
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
        sliderMobile {
          sourceUrl
          localFile {
            childImageSharp {
              fluid(maxWidth: 991) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
      oNas {
        oNasTytul
        oNasTresc
        oNasOpisPodObrazkiem
        oNasObrazek {
          sourceUrl
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 670) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
      portfolio {
        portfolioTytul
        portfolioTresc
        portfolioGaleria {
          portfolioGaleriaNazwaKategorii
          portfolioGaleriaKategoria {
            portfolioGaleriaKategoriaObraz {
              sourceUrl
              localFile {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
            portfolioGaleriaKategoriaOpis
          }
        }
      }
      retuszZdjec {
        retuszZdjecTytul
        retuszZdjecTresc
        retuszZdjecObrazekPrzed {
          sourceUrl
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 670) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
        retuszZdjecObrazekPo {
          sourceUrl
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 670) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
        retuszZdjecOpisPodObrazkiem
      }
      partnerzy {
        partnerzyListaPartnerow {
          partnerzyListaPartnerowNazwa
          partnerzyListaPartnerowZdjecie {
            sourceUrl
            localFile {
              publicURL
              childImageSharp {
                fluid(maxWidth: 123) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
          partnerzyListaPartnerowLink
        }
      }
    }
  }
`