import styled, { keyframes } from 'styled-components';
 
export const intro = keyframes`
    0% {
        opacity 0;
        transform translateY(40px);
    }
        
    100% {
        opacity 1;
        transform translateY(0);
    }
`