import React from "react";
import styled from "styled-components";
import { styles } from "./styles";
import Image from "../components/image"

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 165px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: ${styles.colorPrimary};

  figure {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
  }

  .parallax-inner {
    height: 100%;
    width: 100%;
  }

  & > .gatsby-image-wrapper {
    display: block;
    width: 100%;
  }
`;

const ParallaxPhoto = () => (
    <Wrapper>
        <Image filename={"lodki.jpg"} />
    </Wrapper>
)

export default ParallaxPhoto