import React from "react";
import Slider from "react-slick";
import styled from "styled-components"
import Img from "gatsby-image"
import {graphql, useStaticQuery} from "gatsby";
import "./carousel.css";

const settings = {
  slidesToShow: 7,
  slidesToScroll: 1,
  autoplay: true,
  adaptiveHeight: true,
  accessibility: false,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 450,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const Partner = styled.a`
  .gatsby-image-wrapper {
    height: 123px;
    width: 123px;
  }
`;

const ReactSlickDemo = (data) => {
  return (
      <div className="carousel">
        <Slider {...settings}>
          {console.log(data)}
          {data.data.partnerzyListaPartnerow.map(partner => (
            <Partner href={partner.partnerzyListaPartnerowLink} target="_blank" rel="noopener noreferrer">
              <Img fixed={partner.partnerzyListaPartnerowZdjecie.localFile.childImageSharp.fluid} alt={partner.partnerzyListaPartnerowNazwa} />
            </Partner>
          ))}
        </Slider>
      </div>
  )
}

export default ReactSlickDemo;